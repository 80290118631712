<template>
  <div class="tasting_header">
    <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="tasting_name">{{ tastingInfo.name }}</div>
        <div class="tasting_deets">
          <div class="row">
            <div class="col-5">{{ tastingInfo.location }}</div>
            <div class="col-7 text-right">
              <strong>{{ tastingInfo.date }}</strong> <br />{{ tastingInfo.starttime }} to
              {{ tastingInfo.endtime }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="updateCss2 container">
      <div class="row">
        <div class="col-12">
          <div class="alert alert-danger">
            OOPS! Looks like your browsing an old version. Please clear you history & cache for this site. (development message - will not
            be needed in production)
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TastingHeader',
  props: {
    tastingInfo: Object,
  },
  computed: {},
};
</script>
