<template>
  <div class="tables">
    <div class="table_block" v-for="(table, index) in tables" :key="table.table" :class="{ 'has-ratings': hasRatings(table.skus) }">
      <Table v-bind:table="table" v-bind:alldata="alldata" v-bind:tablesExpanded="tablesExpanded" />
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
  name: 'Tables',
  components: {
    Table,
  },
  props: {
    //msg: String,
    tables: Object,
    alldata: Object,
  },
  data() {
    return {
      tablesExpanded: [],
    };
  },
  methods: {
    hasRatings(tableSkus) {
      // build an array of skus from tableSkus
      const skus = Object.keys(tableSkus);

      //const skus = Array.isArray(tableSkus) ? tableSkus : [];
      const lists = this.alldata.lists || {}; // Ensure lists is an object
      //console.log('Checking for ratings in table with skus: ' + skus.join(', ') + ' and lists: ' + JSON.stringify(lists));
      for (const sku of skus) {
        const ratingsExist = this.alldata.ratings && this.alldata.ratings[sku];
        const hasNonEmptyRatings = ratingsExist && Object.keys(this.alldata.ratings[sku]).length > 0;
        const isRZeroAndNEmpty = ratingsExist && this.alldata.ratings[sku].r == 0 && this.alldata.ratings[sku].n == '';

        let listRatingsExist = false;
        for (const listName in lists) {
          if (listName != 'Uncategorized' && lists.hasOwnProperty(listName)) {
            const list = lists[listName];
            if (list.includes(sku)) {
              listRatingsExist = true;
              //console.log('Found in list: ' + listName + ' for sku: ' + sku);
              break; // Found in list, no need to check further for this SKU
            }
          }
        }

        if (listRatingsExist || (hasNonEmptyRatings && !isRZeroAndNEmpty)) {
          //console.log('Found ratings for table ');
          return true; // If any SKU meets the criteria, return true
        }
      }

      return false; // If no SKUs meet the criteria, return false
    },
  },
  computed: {},
  created() {
    if (this.$store.getters.getTablesExpandedVu.length <= 1) {
      var tablesExpanded = [false];
      for (const table in this.alldata.tables) {
        tablesExpanded[this.alldata.tables[table].table] = false;
      }
      //console.log(this.tablesExpanded);
      if (typeof tablesExpanded != 'undefined') {
        //this.setTablesExpandedVu(this.tablesExpanded);

        this.$store.dispatch('setTablesExpandedVu', tablesExpanded);
        //window.localStorage.setItem('tablesExpandedVu', JSON.stringify(this.tablesExpanded));
      } else {
        //console.log('ERROR expanding tables');
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.table_block.has-ratings {
  background-color: #dcf2e78a;
}
</style>
