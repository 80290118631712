<template>
  <div id="top" class="sku_page page">
    <!--<TastingHeader v-bind:tastingInfo="currentTastingDeets" />-->
    <div class="row">
      <div class="col-12 sp_table_info">
        <div class="title">{{ table_info.table_name }}</div>

        <div class="row">
          <div class="col-4 text-left paddme-left">
            <router-link
              class="btn btn-secondary"
              :to="{ name: 'Sku', params: { sku_id: prev_sku, table_no: table_rollp } }"
              v-if="show_prev"
              >&lt; PREV
            </router-link>
            <span class="btn btn-secondary half-opacity" v-else>&lt; PREV</span>
          </div>
          <div class="col-4 text-center">
            <router-link class="btn btn-outline" :to="{ name: 'Browse', hash: '#tb' + table_info.table }"
              >TABLE {{ table_info.table }}
            </router-link>
          </div>
          <div class="col-4 text-right paddme-right">
            <router-link
              class="btn btn-secondary"
              :to="{ name: 'Sku', params: { sku_id: next_sku, table_no: table_rolln } }"
              v-if="show_next"
              >NEXT &gt; </router-link
            ><span class="btn btn-secondary half-opacity" v-else>NEXT &gt;</span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 sp_table_info sp_table_info_height">
        <div class="title">{{ table_info.table_name }}<br />&nbsp;</div>

        <div class="row">
          <div class="col-4 text-left paddme-left">
            <router-link class="btn btn-secondary" :to="{ name: 'Sku', params: { sku_id: prev_sku } }" v-if="show_prev"
              >&lt; PREV
            </router-link>
            <span class="btn btn-secondary half-opacity" v-else>&lt; PREV</span>
          </div>
          <div class="col-4 text-center">
            <router-link class="btn btn-outline" :to="{ name: 'Browse', hash: '#tb' + table_no }">TABLE {{ table_no }} </router-link>
          </div>
          <div class="col-4 text-right paddme-right">
            <router-link class="btn btn-secondary" :to="{ name: 'Sku', params: { sku_id: next_sku } }" v-if="show_next"
              >NEXT &gt; </router-link
            ><span class="btn btn-secondary half-opacity" v-else>NEXT &gt;</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container content_area">
      <div class="row">
        <div class="col-12 sp_title">
          <h1>
            {{ alldata.skus[sku_id].name }}<span v-if="alldata.skus[sku_id].ats.vintage"> - {{ alldata.skus[sku_id].ats.vintage }}</span>
          </h1>
          <div class="sku">{{ sku_id }}</div>
        </div>
        <div class="col-12 s_note" v-if="alldata.skus[this.sku_id].n">AVAILABILITY: {{ alldata.skus[this.sku_id].n }}</div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <span v-if="false"
            >Producer: <strong>{{ producer_info.name }}</strong></span
          >

          <img :src="getSkuImage" @error="fixMissingImage" class="img-responsive" style="max-height: 300px" height="300" />

          <div class="rating">
            <SkuRating v-bind:sku="sku_id" v-bind:type="alldata.skus[sku_id].type" />
          </div>
          <div class="d-none notes"><SkuNote v-bind:sku="sku_id" v-bind:mytype="alldata.skus[sku_id].type" /></div>
        </div>
      </div>
      <div class="sku_lists" v-if="getSkuLists">{{ getSkuLists }}</div>
      <div class="row" v-if="typeof alldata.ratings[sku_id] != 'undefined' && alldata.ratings[sku_id].n.length != 0">
        <div class="col-12 text-center pb-3">
          <div class="spacer"></div>
          <h3>MY NOTES</h3>
          {{ alldata.ratings[sku_id].n }}
        </div>
      </div>
      <div class="row" v-if="getPricingInfo">
        <div class="col-12 text-center">
          <div class="spacer"></div>
          <div v-for="(lines, index) in getPricingInfo" :key="index">
            <div class="sku_block">
              <table class="sp">
                <tbody>
                  <tr>
                    <td colspan="5" v-html="processString(lines.d)"></td>
                  </tr>
                  <tr class="slidehorz">
                    <td v-for="(price, label) in lines.pricing" :key="label">
                      <span>{{ label }}</span> ${{ cleanPrice(price) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row paddme">
        <div class="col-6 col-md-6 sp_attr paddme-top">
          <SkuAttributes v-bind:attr="alldata.skus[sku_id].ats" v-bind:mytype="alldata.skus[sku_id].type" />
        </div>
        <div class="col-6 col-md-6 text-center" v-if="alldata.skus[this.sku_id].map">
          <img :src="getSkuMap" class="img-responsive" style="max-width: 150px" />
        </div>
      </div>
      <div class="row">
        <div class="col-12 description">
          <div class="spacer"></div>
          <span v-html="alldata.skus[sku_id].d"></span>
        </div>
      </div>

      <div class="row" v-if="alldata.skus[sku_id].reviews.length != 0">
        <div class="col-12">
          <div class="spacer"></div>
          <h2>REVIEWS</h2>
          <div v-for="(reviews, r) in alldata.skus[sku_id].reviews" :key="r" class="review_block">
            <strong>{{ r }} Score: {{ reviews.score }}</strong
            ><br />

            {{ reviews.full }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import SkuRating from '@/components/SkuRating.vue';
import SkuNote from '@/components/SkuNote.vue';
import SkuAttributes from '@/components/SkuAttributes.vue';
import { mapGetters, mapActions } from 'vuex';
import { re } from 'prettier';
//import TastingHeader from '@/components/TastingHeader.vue';

export default {
  name: 'SkuDetail',
  components: {
    SkuRating,
    SkuNote,
    SkuAttributes,
    // TastingHeader,
  },
  props: { sku_id: String, table_no: Number },
  data() {
    return {
      route: useRoute(),
      table_info: {},
      sku_pricing: {},
      producer_info: {},
      prev_sku: String,
      next_sku: String,
      show_prev: false,
      show_next: false,
      table_rollp: Number,
      table_rolln: Number,
      table_number: Number,
    };
  },
  methods: {
    ...mapActions(['fetchAlldata', 'setTableOpen', 'setTablesClosed']),
    cleanPrice(price) {
      return price.replace(/\\"/g, '');
    },
    translate_line(thein) {
      //console.log(thein);
      if (typeof thein != 'undefined') {
        return JSON.parse(thein);
      } else {
        return [];
      }
    },
    checkForPricing(thein) {
      if (typeof thein === 'string') {
        return false;
      } else {
        return true;
      }
    },

    tableInfo() {
      let sku = this.sku_id;
      //for (const table in this.alldata.tables) {
      this.table_number = parseInt(this.table_no, 10); // Ensure table_no is treated as a number
      let table = this.table_number;
      //console.log(`Looking up table_no: ${table}`); // Debugging line
      if (!this.alldata.tables[table]) {
        console.error(`No data found for table_no: ${table}`);
        return; // Early return if table data is missing
      }
      //console.log(`Data for table_no ${table}:`, this.alldata.tables[table]);
      for (const skus in this.alldata.tables[table].skus) {
        //console.log(`Checking SKU: ${skus} against current SKU: ${sku}`);
        if (sku == skus) {
          this.table_info = this.alldata.tables[table];
          //console.log(sku + '--' + skus);
          this.tableNextPrev();
          //console.log('--' + this.table_info.table);
          this.setTablesClosed();
          this.setTableOpen(this.table_info.table);
          this.$store.dispatch('setTableOpen', this.table_info.table);
          return;
        } else {
          //console.log(`No match for SKU: ${skus}`);
        }
      }
      for (const skus in this.alldata.tables[table].skus) {
        if (sku == skus) {
        }
      }
      //}
    },
    tableInfoRev() {
      let sku = this.sku_id;
      let tableNo = parseInt(this.table_no, 10); // Ensure table_no is treated as a number

      // Find the table object where its 'table' property matches tableNo
      for (const tableKey in this.alldata.tables) {
        const tableObj = this.alldata.tables[tableKey];
        if (parseInt(tableObj.table, 10) === tableNo) {
          // Ensure we compare numbers to numbers
          for (const skus in tableObj.skus) {
            if (sku === skus) {
              this.table_info = tableObj;
              //console.log(`Found matching table for SKU: ${sku} in table_no: ${tableNo}`);
              this.tableNextPrev();
              this.setTablesClosed();
              this.setTableOpen(this.table_info.table);
              this.$store.dispatch('setTableOpen', this.table_info.table);
              return; // Exit the method once the matching table and SKU are found
            }
          }
        }
      }

      // Log if no matching table is found
      console.warn(`No matching table found for table_no: ${tableNo} and SKU: ${sku}`);
    },

    tableNextPrev() {
      const sku = this.sku_id;
      const tableNumber = parseInt(this.table_no, 10); // Ensure tableNumber is a number

      // Ensure the current table exists and has SKUs
      if (!this.alldata.tables[tableNumber] || !this.alldata.tables[tableNumber].skus) {
        console.error(`Table ${tableNumber} is not defined or has no SKUs`);
        return;
      }

      const skus = this.alldata.tables[tableNumber].skus;
      const sortedSkus = Object.entries(skus)
        .sort(([, a], [, b]) => parseInt(a.o, 10) - parseInt(b.o, 10)) // Sort SKUs by their order
        .map(([sku]) => sku); // Extract SKU codes

      const currentIndex = sortedSkus.indexOf(sku); // Find the index of the current SKU

      // Determine if there is a previous SKU
      this.show_prev = currentIndex > 0 || tableNumber > this.getMinTableNumber();

      // If the current SKU is the first, set prev_sku to the last SKU of the previous table
      if (currentIndex === 0 && this.show_prev) {
        const prevTableNumber = this.findPreviousTableNumber(tableNumber);
        const prevTableSkus = this.alldata.tables[prevTableNumber].skus;
        const lastSkuOfPrevTable = Object.keys(prevTableSkus).sort(
          (a, b) => parseInt(prevTableSkus[b].o, 10) - parseInt(prevTableSkus[a].o, 10),
        )[0];
        this.prev_sku = lastSkuOfPrevTable;
        this.table_rollp = prevTableNumber; // Set the roll to the previous table
      } else {
        this.prev_sku = currentIndex > 0 ? sortedSkus[currentIndex - 1] : '';
        this.table_rollp = tableNumber;
      }

      // Determine if there is a next SKU
      this.show_next = currentIndex < sortedSkus.length - 1 || tableNumber < this.getMaxTableNumber();

      // If the current SKU is the last, set next_sku to the first SKU of the next table
      if (currentIndex === sortedSkus.length - 1 && this.show_next) {
        const nextTableNumber = this.findNextTableNumber(tableNumber);
        const nextTableSkus = this.alldata.tables[nextTableNumber].skus;
        const firstSkuOfNextTable = Object.keys(nextTableSkus).sort(
          (a, b) => parseInt(nextTableSkus[a].o, 10) - parseInt(nextTableSkus[b].o, 10),
        )[0];
        this.next_sku = firstSkuOfNextTable;
        this.table_rolln = nextTableNumber; // Set the roll to the next table
      } else {
        this.next_sku = currentIndex < sortedSkus.length - 1 ? sortedSkus[currentIndex + 1] : '';
        this.table_rolln = tableNumber;
      }
    },
    // Helper method to find the previous table number
    findPreviousTableNumber(currentTableNumber) {
      const tableNumbers = Object.keys(this.alldata.tables)
        .map(Number)
        .sort((a, b) => a - b);
      const currentIdx = tableNumbers.indexOf(currentTableNumber);
      return currentIdx > 0 ? tableNumbers[currentIdx - 1] : currentTableNumber;
    },

    // Helper method to find the next table number
    findNextTableNumber(currentTableNumber) {
      const tableNumbers = Object.keys(this.alldata.tables)
        .map(Number)
        .sort((a, b) => a - b);
      const currentIdx = tableNumbers.indexOf(currentTableNumber);
      return currentIdx < tableNumbers.length - 1 ? tableNumbers[currentIdx + 1] : currentTableNumber;
    },
    fixMissingImage(event) {
      //console.log('Image not found' + event.target.src);
      //event.target.src = 'https://www.skurnik.com/wp-content/themes/skurnik-twentytwentytwo/images/skurnik_copper_foil.png';
      event.target.src = this.alldata.skus[this.sku_id].th;
    },
    // Helper method to get the minimum table number
    getMinTableNumber() {
      return Math.min(...Object.keys(this.alldata.tables).map(Number));
    },
    // Helper method to get the maximum table number
    getMaxTableNumber() {
      return Math.max(...Object.keys(this.alldata.tables).map(Number));
    },
    processString(str) {
      // Replace pipe characters with span-wrapped pipes
      return str.replace(/\|/g, ' <span style="color:#80817d">&nbsp;|&nbsp;</span> ');
    },
  },
  watch: {
    //table_info: 'tableShow', // 1

    //setTimeout(this.tableShow(), 5000);
    // },
    sku_id: 'tableInfo',
  },
  computed: {
    ...mapGetters(['alldata', 'currentTasting', 'currentTastingDeets', 'getTablesExpandedVu']),

    getSkuImage() {
      if (this.currentTastingDeets.tastingSize == 'large') {
        const baseUrl = 'https://skurnik-dtb.s3.amazonaws.com/';
        const img_url = baseUrl + this.alldata.skus[this.sku_id].th2;
        return img_url;
      } else {
        return this.alldata.skus[this.sku_id].th;
      }
    },
    getSkuMap() {
      var regionMapFile = this.alldata.skus[this.sku_id].map;
      if (this.currentTastingDeets.tastingSize == 'large') {
        // remove this if it is in the regionMapFile name 'https://skurnik-dtb.s3.amazonaws.com//wp-content/themes/skurnik-twentytwentytwo/images/maps/'
        regionMapFile = regionMapFile.replace('/wp-content/themes/skurnik-twentytwentytwo/images/maps/', '');
        const baseUrl = 'https://skurnik-dtb.s3.amazonaws.com/';
        const img_url = baseUrl + regionMapFile;
        return img_url;
      } else {
        const baseUrl = 'https://www.skurnik.com/wp-content/themes/skurnik-twentytwentytwo/images/maps/';
        const img_url = baseUrl + regionMapFile;
        return img_url;
      }
    },
    getSkuNotes() {
      for (const skus in this.alldata.ratings) {
        if (this.sku_id == this.alldata.ratings[skus]) {
          return 'My Notes<br />' + this.alldata.ratings[skus];
        }
      }
      return '';
    },
    getSkuLists() {
      if (typeof this.alldata.lists != 'undefined') {
        //console.log('lists is defined' + typeof this.alldata.lists);
        if (typeof this.alldata.lists == 'object') {
          //console.log('lists is not an object');
          // create a lists object
          var lists = [];
          for (const list in this.alldata.lists) {
            if (this.alldata.lists[list].includes(this.sku_id)) {
              // add list to lists
              lists.push(list);
            }
          }
          if (lists.length > 0) {
            if (lists.length == 1) {
              return 'List: ' + lists.join(', ');
            } else {
              return 'Lists: ' + lists.join(', ');
            }
          } else {
            return '';
          }
        }
      }

      return '';
    },
    getPricingInfo() {
      // OK Lets get our pricing info:
      let sku = this.sku_id;
      var allpricing = JSON.parse(window.localStorage.getItem('pricing'));

      this.sku_pricing = allpricing.data[sku];
      return allpricing.data[sku];
    },
  },
  created() {
    //console.log('created SKU ID:', this.sku_id);
    this.fetchAlldata();

    this.tableInfoRev();
  },
  mounted() {
    if (this.sku_id) {
      //console.log('mounted SKU ID:', this.sku_id);
    } else {
      console.log('mounted SKU ID is undefined');
    }
  },
};
</script>
