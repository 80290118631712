<template>
  <div class="sku_block_row" :data-sku="sku" :class="{ 'has-ratings': hasRatings }">
    <div class="row">
      <div class="col-11">
        <div id="tourSku" v-if="table_info.table === 1"></div>
        <div class="sku_letter" style="display: none">{{ l }}</div>
        <router-link :to="{ name: 'Sku', params: { sku_id: sku, table_no: table_no } }"
          ><div class="sku_name" v-if="alldata.skus[sku].name != undefined" style="position: relative">
            {{ alldata.skus[sku].name }}
            <span v-if="alldata.skus[sku].ats.vintage"> - {{ alldata.skus[sku].ats.vintage }}</span>

            <div>
              <small style="color: #000; font-size: 0.7rem"> {{ sku }}</small>
            </div>
            <div v-if="reviews != ''" style="padding-bottom: 2px; display: none">
              <small>{{ reviews }}&nbsp;&nbsp;</small>
            </div>
            <div class="sku_pricing_short" v-if="showV2Pricing" v-html="showV2Pricing"></div>

            <span v-if="showTableNo">[Table: {{ table_info.table }}]</span>
          </div>
          <div class="sku_name" v-else>No sku name found</div>
        </router-link>
        <!--{{s}}-->
        <SkuRating v-bind:sku="sku" v-bind:type="alldata.skus[sku].type" v-bind:showButton="false" />
        <div v-if="current_list != ''" style="padding-bottom: 2px; padding-top: 4px">
          <small>List: {{ current_list }}</small>
        </div>
        <div class="sku_attrs"></div>
        <div class="sp_attr" v-if="showDetail">
          <SkuAttributes v-bind:attr="alldata.skus[sku].ats" v-bind:mytype="alldata.skus[sku].type" />
        </div>
      </div>
      <div class="col-1 text-center align-center" style="padding-left: 0px" v-if="loggedIn">
        <!--<img src="@/assets/info-circle.svg" style="height: 25px" />
        <div class="spacer"></div>-->
        <!-- <img src="@/assets/list.svg" style="height: 25px" @click.stop="toggleModalTag(sku)" /> -->
        <router-link :to="{ name: 'Sku', params: { sku_id: sku, table_no: table_no } }"
          ><img src="@/assets/chevron-right.svg" style="height: 25px; margin-left: 5px"
        /></router-link>
        <!--<small> &nbsp;ADD TO LIST</small>-->
      </div>
      <div class="col-1 text-center align-center" style="padding-left: 0px" v-else>
        <!--<img src="@/assets/info-circle.svg" style="height: 25px" />
        <div class="spacer"></div>--><span id="tourList" v-if="l == 'A'"></span>
        <!-- <span v-if="table_info.table == 1"></span>
        <img src="@/assets/list.svg" style="height: 25px" @click.stop="toggleModal(sku)" /> -->
        <router-link :to="{ name: 'Sku', params: { sku_id: sku, table_no: table_no } }"
          ><img src="@/assets/chevron-right.svg" style="height: 25px; margin-left: 5px"
        /></router-link>
        <!--<small> &nbsp;ADD TO LIST</small>-->
      </div>
    </div>
  </div>
</template>

<script>
import SkuRating from '@/components/SkuRating.vue';

import SkuAttributes from '@/components/SkuAttributes.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SkuPreview',
  props: {
    sku: String,
    table_no: Number,

    l: String,
    reviews: String,
    showTableNo: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    SkuRating,
    SkuAttributes,
  },
  data() {
    return {
      producer_info: {},
      table_info: {},
      current_list: '',
    };
  },
  computed: {
    ...mapGetters(['alldata', 'loggedIn']),
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    hasRatings() {
      // Check if there are ratings for this.sku
      const ratingsExist = this.alldata.ratings && this.alldata.ratings[this.sku];
      const hasNonEmptyRatings = ratingsExist && Object.keys(this.alldata.ratings[this.sku]).length > 0;
      const isRZeroAndNEmpty = ratingsExist && this.alldata.ratings[this.sku].r == 0 && this.alldata.ratings[this.sku].n == '';
      // Assuming 'lists' is a property of this.alldata and contains the lists
      const lists = this.alldata.lists;

      // Iterate over each list in the lists object
      var listRatingsExist = false;
      for (const listName in lists) {
        if (listName != 'Uncategorized') {
          if (lists.hasOwnProperty(listName)) {
            const list = lists[listName];
            if (list.includes(this.sku)) {
              listRatingsExist = true;
              //console.log('Found in list: ' + listName + ' for sku: ' + this.sku);
              this.current_list = listName;
              break;
            }
          }
        }
      }
      if (!listRatingsExist) {
        //console.log('No list found for sku: ' + this.sku);
      }
      return listRatingsExist || (hasNonEmptyRatings && !isRZeroAndNEmpty);
    },

    showV2Pricing() {
      let sku = this.sku;
      var allpricing = JSON.parse(window.localStorage.getItem('pricing'));

      this.sku_pricing = allpricing.data[sku];
      if (typeof allpricing.data[sku] != 'undefined' && typeof allpricing.data[sku] === 'object') {
        for (const lines of allpricing.data[sku]) {
          var out = lines.d;
          // Drop everything up to and including the first pipe
          out = out.replace(/^[^|]*\| /, ''); // This regex matches any character sequence up to the first pipe and includes the pipe itself
          var pricing = lines.pricing;
          //console.log(out);
          //console.log(pricing)
          if (typeof allpricing.data[sku] === 'object') {
            for (const prices in pricing) {
              // remove \ from pricing[prices]
              pricing[prices] = pricing[prices].replace(/\\"/g, '');
              console.log(pricing[prices]);
              out += '&nbsp;&nbsp;|&nbsp;&nbsp;' + prices + '&nbsp $' + pricing[prices];
            }
            return out;
          }
        }
      }
      return '';
    },
  },
  methods: {
    ...mapActions(['toggleModalTag', 'toggleModalNote', 'toggleModal']),
    producerInfo() {
      let sku = this.sku;
      for (const producerCode in this.alldata.producers) {
        if (producerCode == sku.substring(0, 6)) {
          this.producer_info = this.alldata.producers[producerCode];
        }
      }
    },
    tableInfo() {
      let sku = this.sku;
      for (const table in this.alldata.tables) {
        for (const skus in this.alldata.tables[table].skus) {
          if (sku == this.alldata.tables[table].skus[skus].sku) {
            this.table_info = this.alldata.tables[table];
          }
        }
      }
    },
  },
  mounted() {
    this.producerInfo();
    this.tableInfo();
    if (this.alldata.skus[this.sku] == undefined) {
      // don't do anything in this component.
      //console.log('no type for ' + this.sku);
      return;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sku_block_row.has-ratings {
  background-color: #dcf2e78a; /* Set the desired background color */
}
.align-center {
  display: flex;
  align-items: center;
  justify-content: center; /* This will also horizontally center the content if desired */
}
</style>
